<template>
  <div class="vgt" v-if="render">
    <div class="custom-search d-flex">
      <div class="d-flex mr-auto">
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-1 mr-1"
            style="min-width: 130px;"
            v-b-modal="id"
          >
            <!-- <feather-icon
              icon="ColumnsIcon"
              class="mr-50"
            /> -->
            <span class="align-middle">Freeze Columns</span>
          </b-button>
        </div>
        <!-- <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-1 mr-1"
            style="min-width: 130px;"
            v-b-modal="id"
          >
            <feather-icon
              icon="EyeOffIcon"
              class="mr-50"
            />
            <span class="align-middle">Columns</span>
          </b-button>
        </div> -->
        <!-- <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          type="text"
          class="d-inline-block"
        /> -->
      </div>
      <!-- <div class="d-flex align-items-center">
        <b-button
          v-b-toggle="id"
          class="margin-left-10 mb-1"
          variant="outline-primary"
        >
          <feather-icon
            icon="SettingsIcon"
            class="mr-50"
          />
          <span class="align-middle">Customize</span>
        </b-button>
      </div> -->
    </div>
    <b-sidebar
      :id="id"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-1 py-1">
          <h4 class="mb-0">
            Customize
          </h4>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
        <!-- Options -->
        <div class="mt-2 p-fluid grid select-date-filter">
          <b-tabs>
            <b-tab title="Table" active>
              <h4 class="mt-1">
                Customize table
              </h4>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Make searchable </span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Adds a search bar to the table, allowing a user to search for specific information.'"
                  />
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Stripe table</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Makes every other row on the table light gray.'"
                  />
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Sort table</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Allows you to set an initial sorting based on a column of your choosing and ascending or descending direction.'"
                  />
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Gridlines</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Adds borders on all sides of the table and cells.'"
                  />
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Compact layout</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'In the compact layout, your rows will have less height and therefore less whitespace.'"
                  />
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Hoverable</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Enables a hover state on table rows.'"
                  /> 
                </b-form-checkbox>
              </div>
              <div>
                <b-form-checkbox
                  class="mt-1"
                  checked="false"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="customize-size">Selection</span>
                  <feather-icon
                    icon="InfoIcon"
                    class="customize-info"
                    v-b-tooltip.hover.top="'Provides single selection modes based on row click. If the row is already selected then the row gets unselected.'"
                  /> 
                </b-form-checkbox>
              </div>
              <div class="text-default mt-2">You can use <span class="font-weight-bolder">Custom Views</span> to save your custom table, filters, sorts and selections, without changing the original view.</div>
            </b-tab>
            <b-tab
              title="Columns"
            >
              <h4 class="mt-1">
                Customize columns
              </h4>
              <b-card-text>
                Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert croissant chocolate jujubes fruitcake. Pie cupcake halvah.
              </b-card-text>
              <b-card-text>Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake gummi bears.</b-card-text>
            </b-tab>
            <b-tab
              title="Rows"
            >
              <h4 class="mt-1">
                Customize rows
              </h4>
              <b-card-text>
                Carrot cake dragée chocolate.
              </b-card-text>
            </b-tab>
            <b-tab 
            title="Header"
            >
              <h4 class="mt-1">
                Customize header
              </h4>
              <b-card-text>
                Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
              </b-card-text>
              <b-card-text>
                Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.
              </b-card-text>
            </b-tab>
          </b-tabs>
          <b-form
            class="p-2"
            @submit.prevent
          >
            <!-- Form Actions -->
            <div class="d-flex mt-1">
              <b-button
                style="margin-left: 120px;"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                Apply
              </b-button>
              <b-button
                class="ml-1"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </div>
      </template>
    </b-sidebar>
    <b-modal
      :id="id"
      centered
      title="Freeze Columns"
      size="lg"
      @ok="frozenColum(freezeColum)"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <b-container>
          <b-row style="min-height: 200px;">
            <b-col>
              <b-card-text class="font-weight-bolder">
                Keep the columns visible while scrolling through the rest of the table.
              </b-card-text>
              <b-form-group
                class="mt-1"
                label="Freeze first N columns*"
              >
                <v-select
                  v-model="freezeColum"
                  :dir="'ltr'"
                  label="title"
                  :options="freezeOption"
                />
                <div class="text-default font-small-2">*Allows columns to be fixed from the left side of the table.</div>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <h5 class="mt-1">Hide Columns</h5>
          <b-card-text class="text-default">
            Hide or unhide columns in your table to show just the data that you need to see.
          </b-card-text>
          <b-row>
            <b-col v-for="item in columns.filter(element => {
              return element.hide === true
            })" :key="item.label" cols="4">
              <b-form-checkbox
                v-model="item.hidden"
                :value="true"
              >
                {{ item.name }}
              </b-form-checkbox>
            </b-col>
          </b-row> -->
        </b-container>
      </b-card-text>
    </b-modal>
    <div class="table-rs-responsive">
      <table class="vgt-table bordered">
        <!-- Columns -->
        <thead>
          <tr>
            <th :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-title' + (col.sortable ? ' sortable ' : ' ') + (col.sort === 'desc' ? 'sorting sorting-desc' : col.sort === 'asc' ? 'sorting sorting-asc' : '')" @click="col.sortable ? sortFn(col.label, col.mode, i) : ''" v-show="!col.hidden" v-for="(col, i) in columns" :key="i">{{ col.name }}
            </th>
          </tr>
        </thead>
        <!-- Filters -->
        <thead v-show="columns.filter(element => {
          return element.searchable === true
        }).length > 0">
          <tr>
            <th v-show="!col.hidden" v-for="(col, i) in columns" :key="i" :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-td'">
              <span v-if="col.searchable && col.mode === 'number'">
                <div class="d-flex">
                  <input
                    type="number"
                    class="vgt-input min-input-55"
                    v-model="col.value[0]"
                    @keyup.enter="filterFn(col, col.value)"
                    @input="filterFn(col, col.value)" />
                  <span class="margin-10">to</span>
                  <input
                    type="number"
                    class="vgt-input min-input-55"
                    v-model="col.value[1]"
                    @keyup.enter="filterFn(col, col.value)"
                    @input="filterFn(col, col.value)" />
                </div>
              </span>
              <span v-else-if="col.searchable && col.mode === 'string'">
                <input
                  :placeholder="col.filterOptions.placeholder"
                  type="text"
                  class="vgt-input min-input-55"
                  v-model="col.value"
                  @keyup.enter="filterFn(col, col.value)"
                  @input="filterFn(col, col.value)" />
              </span>
            </th>
          </tr>
        </thead>
        <tr>
          <td v-show="allGroup && !col.hidden" v-for="(col, i) in columns" :key="i" :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-td'">
            <b-button
              v-show="i === 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-secondary"
              class="btn-icon rounded-circle custom-padding-button"
              v-b-tooltip.hover.top="hideGroup ? 'Show ' + allGroup : 'Hide ' + allGroup"
              v-b-modal.edit-btn
              @click="hideGroup ? hideGroup = false : hideGroup = true"
              aria-pressed="false"
            >
              <feather-icon
                :icon="!hideGroup ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              />
            </b-button>
            <b-badge :variant="statusColor" v-show="i === 0">{{ allGroup }} ({{ getTotal() }})
            </b-badge>
            <!-- <span class="bg-light-warning" v-show="i === 0">{{ allGroup }} ({{ getTotal() }})</span> -->
          </td>
        </tr>
        <tbody v-show="!hideGroup" v-for="[key, value] of Object.entries(searched)" :key="key+value">
          <!-- Group -->
          <tr>
            <td v-show="!col.hidden" v-for="(col, i) in columns" :key="i" :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-td'">
              <b-button
                v-show="i === 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle custom-padding-button"
                v-b-tooltip.hover.top="hide[key] ? 'Hide ' + key : 'Show ' + key"
                v-b-modal.edit-btn
                @click="showHide(key)"
                aria-pressed="false"
              >
                <feather-icon
                  :icon="hide[key] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                />
              </b-button>
              <b-badge :variant="(col.hasOwnProperty('styleFn') ? col.styleFn(key, col) : '')" v-show="i === 1">{{ key }} ({{ value.length }})
              </b-badge>
            </td>
          </tr>
          <!-- line -->
          <tr v-show="hide[key]" v-for="item, i in value" :key="i" :class="(active[key] === i ? 'item-selected' : '')" @click="selectItem(key, value, i)" @dblclick="unselected(key)">
            <td v-show="!col.hidden" v-for="(col, ind) in columns" :key="ind" :class="'freeze-' + ((ind + 1) <= freeze.title ? (ind + 1) : 0)  + ' table-rs-td ' + (col.hasOwnProperty('styleFn') ? (col.styleFn(item, col) === 'off-bg' ? (i % 2 == 0 ? ' tr-rs-striped ' : '') : col.styleFn(item, col)) : '')">
              <slot name="row" :col="col" :item="item" :result="myFormat(col, item[col.label])" >{{ myFormat(col, item[col.label]) }}</slot>
            </td>
          </tr>
          <!-- footer -->
          <tr>
            <td v-show="!col.hidden" v-for="(col, i) in columns" :key="i" :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-td ' + (col.hasOwnProperty('styleFn') ? col.styleFn(key, col, c=true) : '')">
              <span v-if="col.footer && col.footerName">
                {{ key }} {{ col.footerName }}
              </span>
              <span v-else-if="col.footer && col.footerFn">
                {{ col.footerFn(value, col.footerLabel) }}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td v-show="!col.hidden" v-for="(col, i) in columns" :key="i" :class="'freeze-' + ((i + 1) <= freeze.title ? (i + 1) : 0) + ' table-rs-td ' + footerClass">
              <span v-if="i === 0">
                {{ totalName }}
              </span>
              <span v-else>
                {{ totalByCol(col.label) }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!-- {{ items }} -->
  </div>
</template>

<script>
import {
  BButton, VBTooltip, BDropdown, BDropdownItem, BFormCheckbox, BRow, BCol, BBadge, BCardText, BSidebar, VBToggle, BForm, BTabs, BTab, BFormInput, BFormGroup, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MultiSelect from 'primevue/multiselect'
import vSelect from 'vue-select'

export default {
  name: 'Table',
  props: {
    items: {
      type: Object,
      require: true
    },
    columns: {
      type: Array,
      require: true
    },
    id: {
      type: String,
      require: true
    },
    allGroup: {
      type: String,
      require: true
    },
    totalName: {
      type: String,
      require: true
    },
    statusColor: {
      type: String,
      require: true
    },
    totalFormat: {
      type: Function,
      require: true
    },
    footerClass: {
      type: String,
      require: true
    },
    freezeOption: {
      type: Array,
      require: true
    }
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    MultiSelect,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BSidebar,
    BForm,
    BTabs, 
    BTab,
    BFormInput,
    BFormGroup,
    BContainer,
    vSelect
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      freezeColum: {
        title: 0
      },
      hide: {},
      render: true,
      columnsSelected: [],
      active: {},
      searched: {},
      sort: 'desc',
      hideGroup: false,
      searchTerm: '',
      oldSearch: '',
      freeze: {
        title: 0
      },
    }
  },
  watch: {
    searchTerm: function (value) {
    },
    columnsSelected: function (value) {
      this.columns.forEach(element => {
        if (element.hide) {
          element.hidden = true
          for (var i=0;i < value.length; i++) {
            if (value[i].label === element.label) {
              element.hidden = false
            }
          }
        }
      })
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    }
  },
  methods: {
    frozenColum (value) {
      this.freeze = value
    },
    totalByCol (col) {
      var total = 0
      if (col.includes('2') || col.includes('revenue')) {
        for (const [key, value] of Object.entries(this.searched)) {
          value.forEach(element => {
            total += element[col]
          })
        }
        return col.includes('2') ? this.totalFormat(total) : col === 'revenue_sum' ? this.totalFormat(total) : ''
      }
      return col.includes('2') ? this.totalFormat(0) : col === 'revenue_sum' ? this.totalFormat(0) : ''
    },
    showHide (key) {
      if (this.hide[key]) {
        this.hide[key] = false
      } else {
        this.hide[key] = true
      }
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
    filterFn (col, value) {
      var result = {}
      this.searched = this.items
      var valid = true
      for (const [key, v] of Object.entries(this.items)) {
        if (!result.hasOwnProperty(key)) {
          result[key] = []
        }
        for (var i=0; i < this.items[key].length;i++) {
          valid = true
          this.columns.forEach(element => {
            if (element === col) {
              element.value = value
            }
            if (element.searchable && !element.filterOptions.filterFn(this.items[key][i][element.label], element.value)) {
              valid = false
            }
          })
          if (valid) {
            result[key].push(this.items[key][i])
          }
        }
      }
      // for (const [key, v] of Object.entries(this.items)) {
      //   result[key] = []
      //   for (var i=0; i < this.items[key].length;i++) {
      //     if (col.filterOptions.filterFn(this.items[key][i][col.label], value)) {
      //       result[key].push(this.items[key][i])
      //     }
      //   }
      // }
      this.searched = result
    },
    sortFn(col, mode='string', i=0) {
      if (this.columns[i].sort === 'desc') {
        this.columns[i].sort = 'asc'
        for (const [key, value] of Object.entries(this.items)) {
          switch(mode) {
            case 'date':
              this.searched[key] = this.searched[key].sort((a, b) => {
                return Number(new Date(a[col]))-Number(new Date(b[col]))
              })
              break
            case 'number':
              this.searched[key] = this.searched[key].sort((a, b) => {
                return a[col]-b[col]
              })
              break
            default:
              this.searched[key] = this.searched[key].sort((a, b) => {
                if (!a[col]) {
                  // Change this values if you want to put `null` values at the end of the array
                  return -1
                }
                if (!b[col]) {
                  // Change this values if you want to put `null` values at the end of the array
                  return +1
                }
                return a[col].localeCompare(b[col]);
              })
          }
        }
      } else if (this.columns[i].sort === 'asc') {
        this.columns[i].sort = ''
        this.searched = this.items
      } else {
        this.columns[i].sort = 'desc'
        for (const [key, value] of Object.entries(this.items)) {
          switch(mode) {
            case 'date':
              this.searched[key] = this.searched[key].sort((a, b) => {
                return Number(new Date(b[col]))-Number(new Date(a[col]))
              })
              break
            case 'number':
              this.searched[key] = this.searched[key].sort((a, b) => {
                return b[col]-a[col]
              })
              break
            default:
              this.searched[key] = this.searched[key].reverse((a, b) => {
                if (!a[col]) {
                  // Change this values if you want to put `null` values at the end of the array
                  return +1
                }
                if (!b[col]) {
                  // Change this values if you want to put `null` values at the end of the array
                  return -1
                }
                return a[col].localeCompare(b[col]);
              })
          }
        }
      }
      
    },
    getTotal () {
      var total = 0
      for (const [key, value] of Object.entries(this.searched)) {
        total += value.length
      }
      return total
    },
    myFormat(col, value) {
      if (col.hasOwnProperty('formatFn')) {
        return col.formatFn(value)
      }
      return value
    }
  },
  created () {
    this.searched = this.items
    this.columnsSelected = this.columns.filter(element => element.hide === true)
    for (const [key, value] of Object.entries(this.items)) {
      this.hide[key] = true
      this.active[key] = null
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.item-selected {
  background-color: #ccc !important;
  background: #ccc !important;
}
.table-rs-title {
  min-width: 200px;
  background: #fff;
  border: 1px solid #ebe9f1;
}
.table-rs-responsive {
  width: 100%;
  overflow: scroll;
  border: 1px solid #ebe9f1;
}
.table-rs-td {
  height: 37px;
  min-width: 250px;
  max-width: 250px;
}
[class*=collapse-] .collapse-title {
  font-size: 20px;
}
.custom-padding-button {
  padding: 8px 8px !important;
  margin-right: 5px;
}
[dir] .vgt-table th.sortable:after {
  margin-top: -7px;
  border-bottom: 5px solid #d8d6de;
}
[dir] .vgt-table th.sortable:before {
  margin-bottom: -7px;
  border-top: 5px solid #d8d6de;
}
.badge {
  font-size: 100% !important;
}
.customize-size {
  font-size: 14px;
}
.customize-info {
  margin-bottom: 5px;
  margin-left: 5px;
}
.nav-tabs {
  font-size: 15px;
}
// .table-rs-responsive th:nth-child(1),
// .table-rs-responsive td:nth-child(1) {
//   position: sticky;
//   left: 0;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(2),
// .table-rs-responsive td:nth-child(2) {
//   position: sticky;
//   left: 200px;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(3),
// .table-rs-responsive td:nth-child(3) {
//   position: sticky;
//   left: 400px;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(4),
// .table-rs-responsive td:nth-child(4) {
//   position: sticky;
//   left: 600px;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(5),
// .table-rs-responsive td:nth-child(5) {
//   position: sticky;
//   left: 800px;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(6),
// .table-rs-responsive td:nth-child(6) {
//   position: sticky;
//   left: 1000px;
//   width: 200px;
//   min-width: 200px;
// }
// .table-rs-responsive th:nth-child(7),
// .table-rs-responsive td:nth-child(7) {
//   position: sticky;
//   left: 1200px;
//   width: 200px;
//   min-width: 200px;
// }

// .table-rs-responsive th:nth-child(1),
// .table-rs-responsive th:nth-child(2),
// .table-rs-responsive th:nth-child(3),
// .table-rs-responsive th:nth-child(4),
// .table-rs-responsive th:nth-child(5),
// .table-rs-responsive th:nth-child(6),
// .table-rs-responsive th:nth-child(7) {
//   background-color: #fff;
//   z-index: 10;
// }
// .table-rs-responsive td:nth-child(1),
// .table-rs-responsive td:nth-child(2),
// .table-rs-responsive td:nth-child(3),
// .table-rs-responsive td:nth-child(4),
// .table-rs-responsive td:nth-child(5),
// .table-rs-responsive td:nth-child(6),
// .table-rs-responsive td:nth-child(7) {
//   z-index: 10;
//   background-color: #fff;
// }
.table-rs-responsive .freeze-1 {
  position: sticky;
  z-index: 10;
  left: 0;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-2 {
  position: sticky;
  z-index: 10;
  left: 250px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-3 {
  position: sticky;
  z-index: 10;
  left: 500px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-4 {
  position: sticky;
  z-index: 10;
  left: 750px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-5 {
  position: sticky;
  z-index: 10;
  left: 1000px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-6 {
  position: sticky;
  z-index: 10;
  left: 1250px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.table-rs-responsive .freeze-7 {
  position: sticky;
  z-index: 10;
  left: 1500px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  background-color: #fff;
}
.tr-rs-striped {
  background-color: #f2f2f2 !important;
}
[dir] .vgt-table.bordered td, [dir] .vgt-table.bordered th {
  border: 1px solid #d8d6de !important;
}
.vs__dropdown-menu {
  max-height: 120px !important;
}
</style>
