<template>
  <div v-if="render">
    <b-row>
      <b-col>
        <b-card style="max-height: 90px;">
          <div class="custom-search d-flex">
            <div class="d-flex mr-auto">
              <h2>
                {{ myDate() }}
              </h2>
            </div>
            <div class="d-flex align-items-center">
              <b-button
                v-b-toggle.sidebar-date-filter
                variant="outline-primary"
                block
              >
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-50"
                />
                <span class="align-middle">Date Filter</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-secondary"
                class="ml-1 btn-icon"
                v-b-tooltip.hover.top="'Download'"
                @click="exportExcel()"
              >
                <feather-icon size="1.5x" icon="DownloadIcon" />
              </b-button>
              <b-sidebar
                id="sidebar-date-filter"
                sidebar-class="sidebar-lg"
                bg-variant="white"
                shadow
                backdrop
                no-header
                right
              >
                <template #default="{ hide }">
                  <!-- Header -->
                  <div class="d-flex justify-content-between align-items-center content-sidebar-header px-1 py-1">
                    <h4 class="mb-0">
                      Date Filter
                    </h4>
            
                    <feather-icon
                      class="ml-1 cursor-pointer"
                      icon="XIcon"
                      size="16"
                      @click="hide"
                    />
                  </div>
                  <!-- Options -->
                  <div class="mt-2 p-fluid grid select-date-filter">
                    <span class="p-float-label">
                      <Dropdown v-model="selectedDate" :options="dateFilter" optionLabel="name"/>
                        <label for="inputtext">Select option</label>
                    </span>
                    <!-- Range option -->
                    <Calendar v-show="showDate === 'DR'" v-model="range" selectionMode="range" :inline="true" dateFormat="dd.mm.yy" class="mt-1"/>
                    <!-- Month option -->
                    <Calendar v-show="showDate === 'M'" v-model="month" view="month" :inline="true" dateFormat="MM yy" class="mt-1"/>
                    <!-- Year option -->
                    <Calendar v-show="showDate === 'Y'" v-model="year" view="year" :inline="true" dateFormat="yy" class="mt-1"/>
                    <!-- Multiple option -->
                    <Calendar v-show="showDate === 'MD'" v-model="multiple" selectionMode="multiple" :inline="true" dateFormat="dd.mm.yy" class="mt-1"/>
                    <b-form
                      class="p-2"
                      @submit.prevent
                    >
                      <!-- Form Actions -->
                      <div class="d-flex mt-1">
                        <b-button
                          style="margin-left: 120px;"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          type="submit"
                          @click="hide && search()"
                        >
                          Apply
                        </b-button>
                        <b-button
                          class="ml-1"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          @click="hide"
                        >
                          Cancel
                        </b-button>
                      </div>
                    </b-form>
                  </div>
                </template>
              </b-sidebar>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          style="max-height: 450px; min-height: 450px;"
          class="card-apply-job"
        >
          <b-card-body>
            <div class="top-avatar mb-1">
              <b-avatar
                class="avatar-report"
                size="70"
                variant="light-danger"
                text="M"
              >
              </b-avatar>
            </div>
            <div class="apply-job-package bg-light-success rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) }} deal<span v-if="(sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-success"
                pill
              >
                Revenue
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) }} deal<span v-if="(sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              70% - 90%
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) }} deal<span v-if="(sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              10% - 90%
              </b-badge>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          no-body
          style="max-height: 450px; min-height: 450px;"
          class="card-apply-job"
        >
          <b-card-body>
            <div class="top-avatar mb-1">
              <b-avatar
                class="avatar-report"
                size="70"
                variant="light-primary"
                text="P"
              >
              </b-avatar>
            </div>
            <div class="apply-job-package bg-light-success rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) }} deal<span v-if="(sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-success"
                pill
              >
                Revenue
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) }} deal<span v-if="(sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              70% - 90%
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) }} deal<span v-if="(sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              10% - 90%
              </b-badge>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          no-body
          style="max-height: 450px; min-height: 450px;"
          class="card-apply-job"
        >
          <b-card-body>
            <div class="top-avatar mb-1">
              <b-avatar
                class="avatar-report"
                size="70"
                variant="light-info"
                text="I"
              >
              </b-avatar>
            </div>
            <div class="apply-job-package bg-light-success rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) }} deal<span v-if="(sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-success"
                pill
              >
                Revenue
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) }} deal<span v-if="(sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              70% - 90%
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) }} deal<span v-if="(sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              10% - 90%
              </b-badge>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          no-body
          style="max-height: 450px; min-height: 450px;"
          class="card-apply-job border-dark"
        >
          <b-card-body>
            <div class="top-avatar mb-1">
              <b-avatar
                class="avatar-report"
                size="70"
                variant="light-dark"
                text="Total"
              >
              </b-avatar>
            </div>
            <div class="apply-job-package bg-light-success rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : [])) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : [])) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) }} deal<span v-if="(sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('META') ? allData.hired.META : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('Perm') ? allData.hired.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumRevTotals((allData.hasOwnProperty('hired') && allData.hired.hasOwnProperty('IHTA') ? allData.hired.IHTA : []), true) + sumRevTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-success"
                pill
              >
                Revenue
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ (sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []))) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ (sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true)) }} deal<span v-if="((sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumForecastTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true)) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              70% - 90%
              </b-badge>
            </div>
            <div class="apply-job-package bg-light-warning rounded">
              <div>
                <sup class="text-body">
                  <small>€</small>
                </sup>
                <h2 class="d-inline mr-25">
                  {{ (sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : [])) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : [])) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []))) | currencyFormat }}
                </h2>
                <sub class="text-body"><small>{{ (sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true)) }} deal<span v-if="((sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('META') ? allData.in_progress.META : []), true) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('Perm') ? allData.in_progress.Perm : []), true) + sumTotals((allData.hasOwnProperty('in_progress') && allData.in_progress.hasOwnProperty('IHTA') ? allData.in_progress.IHTA : []), true)) > 1)">s</span></small></sub>
              </div>
              <b-badge
                variant="light-warning"
                pill
              >
              10% - 90%
              </b-badge>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="card-statistics"
          v-if="series.length > 0"
          >
          <vue-apex-charts
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <Table
        id="in-progress"
        v-if="allData.in_progress"
        :items="allData.in_progress"
        :columns="inProgressColumns"
        statusColor="light-warning"
        allGroup="In Progress"
        totalName="In Progress Total"
        footerClass="ft-in-progress"
        :totalFormat="totalFormatTable"
        :freezeOption="freezeOption">
        <template #row="data">
          <span v-if="data.col.label === 'candidate__name'">
            <a :href="data.item.link" target="_blank">
              <span>{{ data.result }}</span>
            </a>
          </span>
          <span v-else>{{ data.result }}</span>
        </template>
      </Table>
    </b-card>
    <b-card>
      <Table 
      id="hired" 
      v-if="allData.hired" 
      :items="allData.hired" 
      :columns="hiredColumns" 
      statusColor="light-success" 
      allGroup="Hired" 
      totalName="Hired Total"
      footerClass="ft-hired"
      :totalFormat="totalFormatTable"
      :freezeOption="freezeOption">
        <template #row="data">
          <span v-if="data.col.label === 'candidate__name'">
            <a :href="data.item.link" target="_blank">
              <span>{{ data.result }}</span>
            </a>
          </span>
          <span v-else>{{ data.result }}</span>
        </template>
      </Table>
    </b-card>
    <b-button id="hide" style="display: none">hide</b-button>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { BRow, BCol, BBadge, BTable, BButton, BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText, VBToggle, BSidebar, BFormCheckbox, BForm, BDropdown, BDropdownItem, VBTooltip, BDropdownDivider } from 'bootstrap-vue'
import Report from '@/api/services/Report'
// import Ripple from 'vue-ripple-directive'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import { MonthPicker } from 'vue-month-picker'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

import Table from '@/components/PivotTable/Table.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'PivotTable',
  components: {
    draggable,
    BRow,
    BCol,
    BBadge,
    BTable,
    DataTable,
    Column,
    AppCollapse,
    AppCollapseItem,
    FilterMatchMode,
    FilterOperator,
    MonthPicker,
    BButton,
    ColumnGroup,
    Row,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BSidebar,
    Dropdown,
    Calendar,
    BFormCheckbox,
    BForm,
    BDropdown,
    BDropdownItem,
    Table,
    BDropdownDivider,
    VueApexCharts
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  watch: {
    selectedDate: function(value) {
      this.showDate = value.code
      this.month = null
      this.range = null
      this.year = null
    }
  },
  data () {
    return {
      render: true,
      selectedDate: {name: 'Month', code: 'M'},
      showDate: 'M',
      range: null,
      month: null,
      year: null,
      multiple: null,
      dateFilter: [
        {name: 'All', code: 'all'},
        {name: 'Date Range', code: 'DR'},
        {name: 'Month', code: 'M'},
        {name: 'Year', code: 'Y'}
        // {name: 'Multiple Dates', code: 'MD'},
      ],
      fields: [],
      items: [],
      items_hired: [],
      expandedRowGroups: [
        'META', 'Perm', 'IHTA'
      ],
      collapseType: 'default',
      filters: {
        revenue__sum: { value: null, matchMode: FilterMatchMode.DATE_IS }
      },
      total_revenue: 0,
      total_forecast: 0,
      total_revenue_hired: 0,
      total_forecast_hired: 0,
      totals: {},
      totals_hired: {},
      expandedRows: [],
      date: '',
      cols: [],
      colsHired: [], 
      fils: [
        'revenue',
        'process_stage',
        'start_date',
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dec'
      ],
      allData: [],
      inProgressColumns: [],
      hiredColumns: [],
      backupData: {},
      series: [],
      chartOptions: {
        colors: ['#EA5455', '#0d6efd', '#00cfe8', '#FF9F43', '#28C76F'],
        noData: {
          text: undefined,
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          fontFamily: 'cais-sans, Helvetica, Arial, sans-serif',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        fill: {
          opacity: [1, 1, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        labels: [],
        markers: {
          size: 0,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: '',
              hour: ''
            }
          }
        },
        yaxis: {
          title: {
            text: 'Revenue',
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return parseFloat(y).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
              }
              return y;
            }
          }
        }
      },
      freezeOption: [
        {
          title: 0,
        },
        {
          title: 1,
        },
        {
          title: 2,
        },
        {
          title: 3,
        },
        {
          title: 4,
        },
        {
          title: 5,
        },
        {
          title: 6,
        },
        {
          title: 7,
        }
      ]
    }
  },
  methods: {
    totalFormatTable (value) {
      if (!value) {
        return '0.00 €'
      }
      return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
    },
    stringFilter (data, filterString) {
      return String(data).toLowerCase().includes(filterString.toLowerCase())
    },
    numberRangeFilter (data, filterString) {
      let start = filterString[0] === '' ? 0 : Number(filterString[0])
      let end = filterString[1] === '' ? 100 : Number(filterString[1])
      return (data >= start && data <= end)
    },
    exportExcel () {
      Report.excelPivot(this.search(true)).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'pivot_table.xlsx')
        document.body.appendChild(link)
        link.click()
        return
      })
    },
    sumTotals (item, count=false) {
      var result = 0
      var c = 0
      for (let index = 0; index < item.length; index++) {
        if (!item[index].start_date) {
          result += item[index].revenue
          c++
        }
      }
      return count ? c : result
    },
    sumRevTotals (item, count=false) {
      var result = 0
      var c = 0
      for (let index = 0; index < item.length; index++) {
        if (item[index].start_date) {
          result += item[index].revenue
          c++
        }
      }
      return count ? c : result
    },
    sumForecastTotals (item, count=false) {
      var result = 0
      var c = 0
      for (let index = 0; index < item.length; index++) {
        if (item[index].process_stage >= 70 && item[index].process_stage <=90) {
          result += item[index].revenue
          c++
        }
      }
      return count ? c : result
    },
    search (myQuery=false) {
      var query = false
      switch(this.showDate) {
        case 'M':
          var today = new Date()
          var date = this.month ? this.month : this.date
          this.date = date
          var myInprogress = [
            {
              start_date__year: date.getFullYear(),
              start_date__month: date.getMonth() + 1,
              start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
              start_date__lte: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0),'yyyy-mm-dd').format('YYYY-MM-DD'),
              process_stage: '100'
            }
          ]
          if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth()) {
            console.log('here')
            myInprogress.push({
              start_date: null
            })
          }
          query = {
            mode: 'M',
            in_progress: myInprogress,
            hired: [
              {
                start_date__lte: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
                start_date__year: date.getFullYear(),
                start_date__month: date.getMonth() + 1,
                process_stage: '100'
              }
            ],
            groups: [
              {
                name: 'Status',
                field: 'status__description'
              },
              {
                name: 'Type',
                field: 'department__description'
              },
              {
                name: 'Client',
                field: 'customer__name'
              },
              {
                name: 'Role',
                field: 'job__name'
              },
              {
                name: 'Link',
                field: 'link'
              },
              {
                name: 'Candidate',
                field: 'candidate__name'
              },
              // {
              //   name: 'Next Stage',
              //   field: 'next_stage__description'
              // },
              {
                name: 'Process Stage',
                field: 'process_stage'
              },
              {
                name: 'Start Date',
                field: 'start_date'
              }
            ]
          }
          if (myQuery) {
            return query
          }
          this.loadData(query)
          break
        case 'Y':
          var today = new Date()
          var date = this.year ? this.year : this.date
          this.date = date
          var myInprogress = [
            {
              start_date__year: date.getFullYear(),
              start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
              process_stage: '100'
            }
          ]
          if (today.getFullYear() === date.getFullYear()) {
            myInprogress.push({
              start_date: null
            })
          }
          query = {
            mode: 'Y',
            in_progress: myInprogress,
            hired: [
              {
                start_date__year: date.getFullYear(),
                start_date__lte: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
                process_stage: '100'
              }
            ],
            groups: [
              {
                name: 'Status',
                field: 'status__description'
              },
              {
                name: 'Type',
                field: 'department__description'
              },
              {
                name: 'Client',
                field: 'customer__name'
              },
              {
                name: 'Role',
                field: 'job__name'
              },
              {
                name: 'Link',
                field: 'link'
              },
              {
                name: 'Candidate',
                field: 'candidate__name'
              },
              // {
              //   name: 'Next Stage',
              //   field: 'next_stage__description'
              // },
              {
                name: 'Process Stage',
                field: 'process_stage'
              },
              {
                name: 'Start Date',
                field: 'start_date'
              }
            ]
          }
          if (myQuery) {
            return query
          }
          this.loadData(query)
          break
        case 'DR':
          var today = new Date()
          var date = this.range ? this.range : this.date
          this.date = date
          var myInprogress = [
            {
              start_date__gte: moment(date[0],'yyyy-mm-dd').format('YYYY-MM-DD'),
              start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
              start_date__lte: moment(date[1],'yyyy-mm-dd').format('YYYY-MM-DD'),
              process_stage: '100'
            }
          ]
          if (today > date[0] && today < date[1]) {
            myInprogress.push({
              start_date: null
            })
          }
          query = {
            mode: 'DR',
            in_progress: myInprogress,
            hired: [
              {
                start_date__gte: moment(date[0],'yyyy-mm-dd').format('YYYY-MM-DD'),
                start_date__lte: moment(date[1],'yyyy-mm-dd').format('YYYY-MM-DD'),
                start_date__lt: moment(today,'yyyy-mm-dd').clone().add(1,'days').format('YYYY-MM-DD'),
                process_stage: '100'
              }
            ],
            groups: [
              {
                name: 'Status',
                field: 'status__description'
              },
              {
                name: 'Type',
                field: 'department__description'
              },
              {
                name: 'Client',
                field: 'customer__name'
              },
              {
                name: 'Role',
                field: 'job__name'
              },
              {
                name: 'Link',
                field: 'link'
              },
              {
                name: 'Candidate',
                field: 'candidate__name'
              },
              // {
              //   name: 'Next Stage',
              //   field: 'next_stage__description'
              // },
              {
                name: 'Process Stage',
                field: 'process_stage'
              },
              {
                name: 'Start Date',
                field: 'start_date'
              }
            ]
          }
          if (myQuery) {
            return query
          }
          this.loadData(query)
          break
        case 'all':
          this.date = new Date()
          query = {
            mode: 'all',
            groups: [
              {
                name: 'Status',
                field: 'status__description'
              },
              {
                name: 'Type',
                field: 'department__description'
              },
              {
                name: 'Client',
                field: 'customer__name'
              },
              {
                name: 'Role',
                field: 'job__name'
              },
              {
                name: 'Link',
                field: 'link'
              },
              {
                name: 'Candidate',
                field: 'candidate__name'
              },
              // {
              //   name: 'Next Stage',
              //   field: 'next_stage__description'
              // },
              {
                name: 'Process Stage',
                field: 'process_stage'
              },
              {
                name: 'Start Date',
                field: 'start_date'
              }
            ]
          }
          if (myQuery) {
            return query
          }
          this.loadData(query)
          break
        default:
      }
    },
    typeVariant(type) {
      switch(type) {
        case 'META':
          return 'text-danger font-weight-bolder'
        case 'Perm':
          return 'text-primary font-weight-bolder'
        case 'IHTA':
          return 'text-info font-weight-bolder'
        default:
          return 'text-primary font-weight-bolder'
      }
    },
    getDeals (department, onlyForecast=false) {
      var sum = 0
      var l = []
      if (department === 'all') {
        l.push('IHTA')
        l.push('Perm')
        l.push('META')
      } else {
        l.push(department)
      }
      l.forEach(element => {
        if (onlyForecast) {
          if (this.allData.hasOwnProperty('in_progress') && this.allData.in_progress.hasOwnProperty(element)) {
            sum += this.allData.in_progress[element].length
          }
        } else {
          if (this.allData.hasOwnProperty('hired') && this.allData.hired.hasOwnProperty(element)) {
            sum += this.allData.hired[element].length
          }
        }
      })
      return sum
    },
    getMonth () {
      return moment(this.date).format('M')
    },
    getYear () {
      return moment(this.date).format('YYYY')
    },
    myDate () {
      if (this.showDate === 'all') {
        return 'All data'
      }
      if (this.showDate === 'Y') {
        return moment(this.date).format('YYYY')
      }
      if (this.showDate === 'DR') {
        return moment(this.date[0]).format('DD/MM/YYYY') + ' - ' + moment(this.date[1]).format('DD/MM/YYYY')
      }
      if (moment(this.date).format('MMMM YYYY')) {
        return moment(this.date).format('MMMM YYYY')
      }
      return ''
    },
    dateTable (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    filterCallback (date) {
      if (String(date.monthIndex) !== moment(this.date).format('M') || String(date.from.getFullYear()) !== moment(this.date).format('YYYY')) {
        if (String(date.from.getFullYear()) !== moment(this.date).format('YYYY')) {
          this.date = date.from.setMonth(0)
        }
        this.date = date.from
        this.loadData()
        document.getElementById("hide").click()
        document.getElementById("hide").click()
        document.getElementById("hide").click()
      }
      // this.date = date
      // this.loadData()
    },
    styleCurrency(item, i=false, show=true) {
      var result = 0
      if (!Array.isArray(item)) {
        for (let key in item) { 
          let value
          value = item[key]
          if (value.revenue) {
            result += value.revenue
          }
        }
      } else if (Array.isArray(item)) {
        for (let key in item[i]) { 
          let value
          value = item[i][key]
          if (value.revenue) {
            result += value.revenue
          }
        }
      } else {
        result = item
      }
      if (!result) {
        return this.getaMonth(i) && show ? this.getaMonth(i) + ' 0.00 €' : '0.00 €'
      }
      return this.getaMonth(i) && show ? this.getaMonth(i) + ' ' +  parseFloat(result).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €' : parseFloat(result).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
    },
    getaMonth (i) {
      if ((this.showDate === 'DR' || this.showDate === 'all') && this.range) {
        i = this.range[0].getMonth() + i
        if (i > 23) {
          i = i - 24
        }
        if (i > 11) {
          i = i - 12
        }
      }
      var names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
      return names[i]
    },
    log (event) {
      this.items = []
      this.loadData()
    },
    loadData (query=false, chart=true) {
      this.$loading(true)
      this.render = false
      if (!query) {
        query =  {
          date: this.date,
          groups: [
            {
              "name": "Status",
              "field": "status__description"
            },
            {
              "name": "Type",
              "field": "department__description"
            },
            {
              "name": "Client",
              "field": "customer__name"
            },
            {
              "name": "Role",
              "field": "job__name"
            },
            {
              "name": "Candidate",
              "field": "candidate__name"
            },
            {
              "name": "Process Stage",
              "field": "process_stage"
            },
            {
              "name": "Start Date",
              "field": "start_date"
            }
          ],
          fields: {
            // 'status__description': 'In progress',
          }
        }
      }
      this.hiredColumns = [
        {
          name: 'Status',
          labelGroup: 'key',
          label: 'key',
          hide: false,
          group: true,
          hidden: false,
          searchable: false,
          mode: 'string',
          footer: true,
          footerName: '',
          sort: '',
          sortable: false
        },
        {
          name: 'Department',
          label: 'key',
          hide: false,
          group: true,
          hidden: false,
          searchable: false,
          mode: 'string',
          footer: true,
          footerName: 'Total',
          sort: '',
          sortable: false,
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
        },
        {
          name: 'Client',
          label: 'customer__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search client',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Candidate',
          label: 'candidate__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search candidate',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Role',
          label: 'job__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search role',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Process Stage',
          label: 'process_stage',
          hide: true,
          group: false,
          hidden: false,
          searchable: false,
          mode: 'number',
          filterOptions: {
            placeholder: 'Search process stage',
            filterFn: this.numberRangeFilter
          },
          formatFn: (value) => {
            return value + ' %'
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: [0, 100],
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Start Date',
          label: 'start_date',
          hide: true,
          group: false,
          hidden: false,
          searchable: false,
          mode: 'date',
          footer: false,
          sort: '',
          sortable: true,
          formatFn: (value) => {
            if (value === '' || value === null) {
              return ''
            }
            return moment(new Date(value),'yyyy-mm-dd').format('DD/MM/YYYY')
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
        }
      ]
      this.inProgressColumns = [
        {
          name: 'Status',
          labelGroup: 'key',
          label: 'key',
          hide: false,
          group: true,
          hidden: false,
          searchable: false,
          mode: 'string',
          footer: true,
          footerName: '',
          sort: '',
          sortable: false,
        },
        {
          name: 'Department',
          labelGroup: 'key',
          label: 'key',
          hide: false,
          group: true,
          hidden: false,
          searchable: false,
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          mode: 'string',
          footer: true,
          footerName: 'Total',
          sort: '',
          sortable: false
        },
        {
          name: 'Client',
          label: 'customer__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search client',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Candidate',
          label: 'candidate__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search candidate',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Role',
          label: 'job__name',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          filterOptions: {
            placeholder: 'Search role',
            filterFn: this.stringFilter
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: '',
          mode: 'string',
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Process Stage',
          label: 'process_stage',
          hide: true,
          group: false,
          hidden: false,
          searchable: true,
          mode: 'number',
          filterOptions: {
            placeholder: 'Search process stage',
            filterFn: this.numberRangeFilter
          },
          formatFn: (value) => {
            return value + ' %'
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
          value: [0, 100],
          footer: false,
          sort: '',
          sortable: true
        },
        {
          name: 'Start Date',
          label: 'start_date',
          hide: true,
          group: false,
          hidden: false,
          searchable: false,
          mode: 'date',
          footer: false,
          sort: '',
          sortable: true,
          formatFn: (value) => {
            if (value === '' || value === null) {
              return ''
            }
            return moment(new Date(value),'yyyy-mm-dd').format('DD/MM/YYYY')
          },
          styleFn: (row, col, c='') => {
            if (row.hasOwnProperty('department__description')) {
              if (row['department__description'] === 'META') {
                return 'bg-meta'
              } else if (row['department__description'] === 'Perm') {
                return 'bg-perm'
              } else if (row['department__description'] === 'IHTA') {
                return 'bg-ihta'
              }
            } else if (c === true) {
              if (row === 'META' ) {
                return 'ft-meta'
              } else if (row === 'Perm') {
                return 'ft-perm'
              } else if (row === 'IHTA') {
                return 'ft-ihta'
              }
            } else {
              if (row === 'META' ) {
                return 'light-danger'
              } else if (row === 'Perm') {
                return 'light-primary'
              } else if (row === 'IHTA') {
                return 'light-info'
              }
            }
          },
        }
      ]
      Report.getPivotTable(query).then(response => {
        this.allData = []
        var months = []
        var valid = true
        var total = 0
        if (response.data.results.hired.META.length > 0 || response.data.results.hired.Perm.length > 0 || response.data.results.hired.IHTA.length > 0) {
          while (valid === true) {
            for (const [key, value] of Object.entries(response.data.results.hired)){
              if (response.data.results.hired[key].length > 0) {
                for (const [k, v] of Object.entries(response.data.results.hired[key][0])) {
                  if (k.includes('2')) {
                    months.push(k)
                  }
                }
                valid = false
                break
              }
            }
          }
        } else if (response.data.results.in_progress.META.length > 0 || response.data.results.in_progress.Perm.length > 0 || response.data.results.in_progress.IHTA.length > 0) {
          while (valid === true) {
            for (const [key, value] of Object.entries(response.data.results.in_progress)){
              if (response.data.results.in_progress[key].length > 0) {
                for (const [k, v] of Object.entries(response.data.results.in_progress[key][0])) {
                  if (k.includes('2')) {
                    months.push(k)
                  }
                }
                valid = false
                break
              }
            }
          }
        }
        months.sort((a, b) => {
          var as = a.split('_')
          var bs = b.split('_')
          var ad = new Date(as[0] + ' 1,' + as[1])
          var bd = new Date(bs[0] + ' 1,' + bs[1])
          return ad.getTime() - bd.getTime()
        }).forEach(element => {
          this.hiredColumns.push(
            {
              name: element.charAt(0).toUpperCase() + element.slice(1).replace('_', ' '),
              label: element,
              hide: false,
              group: false,
              hidden: false,
              searchable: false,
              mode: 'number',
              footer: true,
              footerLabel: element,
              footerFn: (data, col) => {
                var value = data.reduce((accumulator, object) => {
                  return accumulator + parseFloat(object[col])
                }, 0)
                // if (!value) {
                //   return ''
                // }
                return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
              },
              sort: '',
              sortable: true,
              formatFn: (value) => {
                if (!value) {
                  return ''
                }
                return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
              },
              styleFn: (row, col, c='') => {
                if (row.hasOwnProperty('department__description')) {
                  return 'off-bg'
                } else if (c === true) {
                  if (row === 'META' ) {
                    return 'ft-meta'
                  } else if (row === 'Perm') {
                    return 'ft-perm'
                  } else if (row === 'IHTA') {
                    return 'ft-ihta'
                  }
                }
              },
            }
          )
          total = 0
          for (const [key, value] of Object.entries(response.data.results.in_progress)){
            for (var i=0; i < response.data.results.in_progress[key].length; i++) {
              total += response.data.results.in_progress[key][i][element]
            }
          }
          if (total > 0){
            this.inProgressColumns.push(
              {
                name: element.charAt(0).toUpperCase() + element.slice(1).replace('_', ' '),
                label: element,
                hide: false,
                group: false,
                hidden: false,
                searchable: false,
                mode: 'number',
                footer: true,
                footerLabel: element,
                footerFn: (data, col) => {
                  var value = data.reduce((accumulator, object) => {
                    return accumulator + parseFloat(object[col])
                  }, 0)
                  // if (!value) {
                  //   return ''
                  // }
                  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
                },
                sort: '',
                sortable: true,
                formatFn: (value) => {
                  if (!value) {
                    return ''
                  }
                  return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
                },
                styleFn: (row, col, c='') => {
                  if (row.hasOwnProperty('department__description')) {
                    return 'off-bg'
                } else if (c === true) {
                    if (row === 'META' ) {
                      return 'ft-meta'
                    } else if (row === 'Perm') {
                      return 'ft-perm'
                    } else if (row === 'IHTA') {
                      return 'ft-ihta'
                    }
                  }
                },
              }
            )
          }
        })
        this.inProgressColumns.push(
          {
            name: 'Forecast',
            label: 'revenue_sum',
            hide: false,
            group: false,
            hidden: false,
            searchable: false,
            mode: 'number',
            footer: true,
            footerLabel: 'revenue_sum',
            footerFn: (data, col) => {
              var value = data.reduce((accumulator, object) => {
                return accumulator + parseFloat(object[col])
              }, 0)
              // if (!value) {
              //   return ''
              // }
              return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
            },
            sort: '',
            sortable: true,
            formatFn: (value) => {
              if (!value) {
                return ''
              }
              return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
            },
            styleFn: (row, col, c='') => {
              if (row.hasOwnProperty('department__description')) {
                return 'off-bg'
              } else if (c === true) {
                if (row === 'META' ) {
                  return 'ft-meta'
                } else if (row === 'Perm') {
                  return 'ft-perm'
                } else if (row === 'IHTA') {
                  return 'ft-ihta'
                }
              }
            },
          })
        this.allData = response.data.results
        if (chart) {
          this.getChart(query)
        }
        this.$nextTick(() => {
          this.render = true
        })
        this.$loading(false)
      })
    },
    getChart(query, create=true) {
      var today = new Date()
      console.log(query)
      if (query.hasOwnProperty('in_progress')) {
        if (Array.isArray(this.date) || (today.getFullYear() === this.date.getFullYear() && today.getMonth() === this.date.getMonth())) {
          query.in_progress.pop()
        }
        if (query.mode === 'DR' && create) {
          query.in_progress.push({
            start_date__gte: moment(this.date[0],'yyyy-mm-dd').format('YYYY-MM-DD'),
            start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
            start_date__lte: moment(this.date[1],'yyyy-mm-dd').format('YYYY-MM-DD'),
            process_stage: '100'
          })
        }
        query.in_progress.push({
          start_date: null,
          process_stage__lte: 90,
          process_stage__gte: 70,
        })
      }
      this.chartOptions.labels = []
      this.series = []
      Report.getChart(query).then(response => {
        this.chartOptions.labels = response.data.labels
        this.series = [
          {
            name: 'META',
            type: 'column',
            data: response.data.series.META
          },
          {
            name: 'Perm',
            type: 'column',
            data: response.data.series.Perm
          },
          {
            name: 'IHTA',
            type: 'column',
            data: response.data.series.IHTA
          },
          {
            name: 'Forecast',
            type: 'area',
            data: response.data.series.forecast
          }, {
            name: 'Revenue',
            type: 'line',
            data: response.data.series.revenue
          }
        ]
        if (query.mode === 'M') {
          this.chartOptions.xaxis = {
            categories: [this.myDate()],
            labels: {
              show: false
            }
          }
        } else {
          this.chartOptions.xaxis = {
            type: 'datetime',
            labels: {
              datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: '',
                hour: ''
              }
            }
          }
        }
        this.render = false
        this.$nextTick(() => {
          this.render = true
        })
      })
    }
  },
  created () {
    this.backupData = Object.create(this)
    var today = new Date()
    var date = new Date()
    this.date = date
    var myInprogress = [
      {
        start_date__year: date.getFullYear(),
        start_date__month: date.getMonth() + 1,
        start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
        start_date__lte: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0),'yyyy-mm-dd').format('YYYY-MM-DD'),
        process_stage: '100'
      }
    ]
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth()) {
      myInprogress.push({
        start_date: null
      })
    }
    var query = {
      mode: 'M',
      in_progress: myInprogress,
      hired: [
        {
          start_date__lte: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
          start_date__year: date.getFullYear(),
          start_date__month: date.getMonth() + 1,
          process_stage: '100'
        }
      ],
      groups: [
        {
          name: 'Status',
          field: 'status__description'
        },
        {
          name: 'Type',
          field: 'department__description'
        },
        {
          name: 'Client',
          field: 'customer__name'
        },
        {
          name: 'Role',
          field: 'job__name'
        },
        {
          name: 'Link',
          field: 'link'
        },
        {
          name: 'Candidate',
          field: 'candidate__name'
        },
        {
          name: 'Process Stage',
          field: 'process_stage'
        },
        {
          name: 'Start Date',
          field: 'start_date'
        }
      ]
    }
    this.loadData(query, false)
    var nd = new Date()
    nd = new Date(nd.setMonth(nd.getMonth() - 12))
    var chartQuery = {
      mode: 'DR',
      in_progress: [
        {
          start_date__year: date.getFullYear(),
          start_date__month: date.getMonth() + 1,
          start_date__gt: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
          start_date__lte: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0),'yyyy-mm-dd').format('YYYY-MM-DD'),
          process_stage: '100'
        },
        {
          start_date: null
        }
      ],
      hired: [
        {
          start_date__lte: moment(today,'yyyy-mm-dd').format('YYYY-MM-DD'),
          start_date__gte: moment(new Date(nd.getFullYear(), nd.getMonth(), 0),'yyyy-mm-dd').format('YYYY-MM-DD'),
          process_stage: '100'
        }
      ],
      groups: [
        {
          name: 'Status',
          field: 'status__description'
        },
        {
          name: 'Type',
          field: 'department__description'
        },
        {
          name: 'Client',
          field: 'customer__name'
        },
        {
          name: 'Role',
          field: 'job__name'
        },
        {
          name: 'Link',
          field: 'link'
        },
        {
          name: 'Candidate',
          field: 'candidate__name'
        },
        {
          name: 'Process Stage',
          field: 'process_stage'
        },
        {
          name: 'Start Date',
          field: 'start_date'
        }
      ]
    }
    this.getChart(chartQuery, false)
  }
}
</script>

<style lang="scss">
.off-bg {
  background-color: #fff !important;
}
.ft-meta {
  background-color: #fdebeb !important;
  font-weight: 900 !important;
}
.ft-perm {
  background-color: #e5eeff !important;
  font-weight: 900 !important;
}
.ft-ihta {
  background-color: #e7fafd !important;
  font-weight: 900 !important;
}
.ft-in-progress {
  background-color: #fff4e9 !important;
  font-weight: 900 !important;
}
.ft-hired {
  background-color: #eaf9ef !important;
  font-weight: 900 !important;
}
.bg-months {
  background-color: #e9ecef !important;
}
.bold-months {
  background-color: #e9ecef !important;
  font-weight: 900 !important;
}
.bg-meta {
  background-color: #fdebeb !important;
}
.bg-perm {
  background-color: #e5eeff !important;
}
.bg-ihta {
  background-color: #e7fafd !important;
}
.pt-border {
  border: 1px solid #CCC;
  padding: 10px;
}
.p-rowgroup-footer td {
    font-weight: 700;
}

:deep(.p-rowgroup-header) {
  span {
    font-weight: 700;
  }

  .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
  }
}
[dir] .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, [dir] .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, [dir] .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, [dir] .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,0,0,0) !important;
}
.image-text {
  margin-left: 10px;
}
.p-component {
  font-family: "cais-sans", Helvetica, Arial, sans-serif !important;
  font-size: 1rem;
  font-weight: normal;
}
[dir] .p-column-filter-menu-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(0,0,0,0) !important;
}
[dir] .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  display: none;
}
.p-column-filter-buttonbar {
  display: none;
}
.avatar-report {
  .b-avatar-text {
    span {
      font-size: 20px !important;
    }
  }
}
.design-planning-wrapper {
  display: flex;
  align-items: right;
  flex-wrap: wrap;
  margin-top: 10px;


  .design-planning {
    padding: 0.5rem;
    margin-bottom: 0.7rem;
    text-align: center;
    background-color: #F1F1F1;
    border-radius: 5px;
    min-width: 6.4rem;
    &:not(:last-child) {
      margin-right: 0.7rem;
    }
  }
}
.card-statistics {
  min-height: 233px;
  .statistics-body {
    padding: 2rem 2.4rem !important;
  }
}
.p-inputtext {
  font-family: "cais-sans", Helvetica, Arial, sans-serif !important;
}
.p-link {
  font-family: "cais-sans", Helvetica, Arial, sans-serif !important;
}
.p-datepicker table td > span.p-highlight {
  background: #dceaff !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover {
  color: #0d63fd !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #0d63fd !important;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  background: #dceaff !important;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  background: #dceaff !important;
}
.custom-switch .custom-control-label .switch-icon-right {
  right: 98px !important;
}
.p-datatable .p-sortable-column.p-highlight {
  color: #0d63fd !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #0d63fd !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #dceaff !important;
  background: #dceaff !important;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid #dceaff !important;
  outline-offset: -0.15rem !important;
  background: #dceaff !important;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: inset 0 0 0 0.15rem #a8c9f9 !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: transparent !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #dceaff !important;
}
.dropdown-menu {
  min-width: 280px !important;
}
.card-height {
  min-height: 259px !important;
}
[dir] table.vgt-table {
  background-color: white !important;
  border: 1px solid #c2c0c6 !important;
}
.top-avatar {
  text-align: center;
}
</style>
