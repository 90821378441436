<template>
  <div id="app">
    <pivot-table
      :source="rows"
      :columns="columns"
    ></pivot-table>
  </div>
</template>
 
<script>
import PivotTable from "@/components/PivotTable/PivotTable.vue";
export default {
  name: "DashboardTable",
  data() {
    return {
      columns: [{ prop: "name" }, { prop: "details" }],
      rows: [{
        name: "1",
        details: "Item 1",
      }]
    };
  },
  components: {
    PivotTable,
  },
};
</script>