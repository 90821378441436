<template>
  <dashboard-table></dashboard-table>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import DashboardTable from '@/components/DashboardTable.vue'

export default {
  components: {
    BCard,
    DashboardTable
  },
}
</script>

<style>
</style>
